.vh100 {
    height: 100vh;
}

.w100 {
    width: 100%;
}

.tac {
    text-align: center;
}

.logo {
    @include iconmask('/assets/logo.svg', 50px, 50px, linear-gradient(125deg, #D844A6, #00C2B4));

    &.large {
        @include iconmask('/assets/logo.svg', 150px, 150px, linear-gradient(125deg, #D844A6, #00C2B4));
    }
}