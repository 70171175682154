.mainContent {
    margin: 5.5rem 1.5rem 1rem 15.25rem;
    padding: 1rem;
    transition: margin-left 0.2s ease-in-out;

    &.compact {
        margin-left: 6.5rem;
    }
}

.mainSider {
    border-right: 0;
    height: 100%;
    padding: 1.5rem 0;
    position: fixed !important;
    left: 0;
    top: 64px;
    bottom: 0;
}

.mainHeader {
    position: fixed;
    padding: 0 1rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}