.authBox {
    .ant-tabs-nav {
        margin: unset;
    }

    .loginTabs {
        width: 310px;
        border: solid #f0f0f0;
        border-width: 0px 1px 1px 1px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        padding: 20px !important;
    
        * input, * button {
            width: 100% !important;
        }
    }
    
    .reCaptcha {
        display: flex;
        justify-content: center;
    }
}

.activation-card {
    margin: 0 1rem;
    max-width: 40rem;
    
    .ant-card-head {
        background-color: #1677ff;
        color: #fff;
    }
}