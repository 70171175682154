@mixin iconmask(
    $icon: null,
    $width: 1rem,
    $height: 1rem,
    $color: null,
    $hoverColor: null,
    $side: false
   ) {
    cursor: pointer;
    position: relative;
    @if $side == false {
        width: $width;
        height: $height;
    } @else {
        padding-left: calc($width + 1rem);
    }
    background-color: rgba(255, 255, 255, 0);
    border: none;
    z-index: 1;

    &::before {
        position: absolute;
        width: $width;
        height: $height;
        top: 0;
        left: 0;
        content: "";
        -webkit-mask: url($icon) no-repeat 100% 100%;
        mask: url($icon) no-repeat 100% 100%;
        -webkit-mask-size: cover;
        mask-size: cover;
        @if $color != null {
            background: $color;
        }
    }
}
